angular
  .module('app')
  .factory('mainListService', [
    '$http',
    '$q',
    'mainService',
    mainListServiceFactory
  ])

function mainListServiceFactory($http, $q, mainService) {
  var self = {
    getList: function(options) {
      return mainService.getList(options)
    },
    get: function(id) {
      return mainService.get(id)
    },
    getCompetences: function(group) {
      return mainService.getCompetences(group)
    },
    getDistricts: function() {
      return mainService.userDistricts()
    },
    getGlobalDistricts: function() {
      return mainService.getDistricts()
    },
    listColumns: function(userid) {
      return globalConfig.bky.columns.defaultColumns
    }
  }
  return self
}
