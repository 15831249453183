// Configuration

console.log('Host name:' + document.location.hostname)

if(document.location.hostname === 'localhost') {
  localStorage.setItem('apiHost', 'https://bky-api.bowser.se')
  localStorage.setItem('authHost', 'https://auth.bowser.se')
}
 
if(document.location.hostname === 'bky.bowser.se') {
  localStorage.setItem('apiHost', 'https://bky-api.bowser.se')
  localStorage.setItem('authHost', 'https://auth.bowser.se')
}

if(document.location.hostname === 'bky-test.fawful.se') {
  localStorage.setItem('apiHost', 'https://bky-api.fawful.se')
  localStorage.setItem('authHost', 'https://auth.fawful.se')
}

if(document.location.hostname === 'bky.arriva.guru' || document.location.hostname ===  'https://bky-prod.arriva.guru'){
  localStorage.setItem('apiHost', 'https://bky-api.arriva.guru')
  localStorage.setItem('authHost', 'https://auth.arriva.guru')
}

var apiHost = localStorage.getItem('apiHost') || document.location.protocol + '//bky.' + document.location.hostname.split('.').splice(-2).join('.')
var authHost = localStorage.getItem('authHost') || document.location.protocol + '//auth.' + document.location.hostname.split('.').splice(-2).join('.')

console.info('apiHost: %s', apiHost)
console.info('authHost: %s', authHost)

window.globalConfig = {
  api: {
    districts: {
      path: apiHost + '/api/district'
    },
    staff: {
      path: apiHost + '/api/staff'
    },
    competence: {
      path: apiHost + '/api/competence'
    },
    competenceGroup: {
      path: apiHost + '/api/competenceGroup'
    },
    ExpectedCompetence: {
      path: apiHost + '/api/ExpectedCompetence'
    },
  },
  bky: {
    columns: {
      defaultColumns: ['B', 'D1', 'D1E', 'D', 'DE', 'YKBBuss', 'Förarkort']
    },
    order: [
      'B',
      'B96',
      'BE',
      'C1',
      'C1E',
      'C',
      'CE',
      'D1',
      'D1E',
      'D',
      'DE',
      'Förarkort'
    ]
  },
  auth:{
    clientId: 'bky_ui_spa',
    redirectUri: 'https://' + document.location.hostname + '/#/sso_callback#',
    exchangeTokenEndpoint: authHost + '/api/token/exchange',
    accessToken : 'authSessionData_accessToken',
    id_token : 'authSessionData_idToken',
    accessTokenType : 'authSessionData_accessTokenType',
    tokenExpiresAt : 'authSessionData_tokenExpiresAt',
    ssoUser : 'authSessionData_ssoUser',
    endSessionEndpoint: authHost + '/connect/endsession',
    ssoState: 'ssoState',
    login: authHost + '/Account/Login'
  }
}

var app = angular.module('app', ['ui.bootstrap'])
