/*****************************************************************************
 *  apiFactory 2.0.0
 *  2016-09-16
 * 
 * 
 * (c) Tomasz Kamiński <tomasz.kaminski@euvic.pl> 2016
 * (c) Odd Holstensson <odd.holstensson@arriva.se> 2016
 *****************************************************************************/

(function () {
  'use strict'

  function apiFactory($http, $q) {

    function get(apiUrl, conf) {
      var defered = $q.defer()
      $http.get(apiUrl, conf || {}).then(function (data) {
        defered.resolve(data)
      }, function (data) {
        defered.reject(data)
      })
      return defered.promise
    }

    function put(apiUrl, data, conf) {
      var defered = $q.defer()
      $http.put(apiUrl, data || {}, conf || {}).then(function (data) {
        defered.resolve(data)
      }, function (data) {
        defered.reject(data)
      })
      return defered.promise
    }

    function post(apiUrl, data, conf) {
      var defered = $q.defer()
      $http.post(apiUrl, data || {}, conf || {}).then(function (data) {
        defered.resolve(data)
      }, function (data) {
        defered.reject(data)
      })
      return defered.promise
    }

    function del(apiUrl, conf) {
      var defered = $q.defer()
      $http.delete(apiUrl, conf || {}).then(function (data) {
        defered.resolve(data)
      }, function (data) {
        defered.reject(data)
      })
      return defered.promise
    }

    function genericPost(funcBefore, funcAfter, funcError, apiUrl, postData, conf) {
      if (funcBefore) {
        funcBefore()
      }
      post(apiUrl, postData, conf).then(function (data) {
        return funcAfter(data)
      }, function (e) {
        funcError(e)
      })
    }

    function genericDelete(funcBefore, funcAfter, funcError, apiUrl, conf) {
      if (funcBefore) {
        funcBefore()
      }
      del(apiUrl, conf).then(function (data) {
        return funcAfter(data)
      }, function (e) {
        funcError(e)
      })
    }

    function genericPut(funcBefore, funcAfter, funcError, apiUrl, putData, conf) {
      if (funcBefore) {
        funcBefore()
      }
      put(apiUrl, putData, conf).then(function (data) {
        if (funcAfter) {
          return funcAfter(data)
        }
      }, function (e) {
        if (funcError) {
          funcError(e)
        }
      })
    }

    function genericGet(funcBefore, funcAfter, funcError, apiUrl, conf) {
      if (funcBefore) {
        funcBefore()
      }
      get(apiUrl, conf).then(function (data) {
        return funcAfter(data)
      }, function (e) {
        funcError(e)
      })
    }

    function getDefaultConfigWithAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.authSessionData_accessToken,
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    }

    return {
      get: get,
      post: post,
      genericPost: genericPost,
      genericGet: genericGet,
      genericPut: genericPut,
      put: put,
      del: del,
      genericDelete: genericDelete,
      getDefaultConfigWithAuthHeader: getDefaultConfigWithAuthHeader
    }
  }

  angular.module('app').factory('apiFactory', ['$http', '$q', apiFactory])
})()