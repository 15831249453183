angular
  .module('app')
  .controller('loginCtrl', [
    '$scope',
    '$timeout',
    '$uibModalInstance',
    'data',
    loginController
  ])

function loginController($scope, $timeout, $uibModalInstance, data) {
  $scope.service = data.service
  $scope.language = 'se'
  $scope.i18n = {
    se: {
      modalTitle: 'Logga in',
      emailAdress: 'E-postadress',
      password: 'Lösenord',
      tfaCode: 'Kod',
      passwordForgot: 'Glömt lösenordet?',
      tryLogin: 'Testa att logga in igen.',
      passwordResetSuccess:
        'En lösenordsåterställningslänk har skickats till dig via e-post. Följ instruktionerna i detta och testa sedan att logga in igen.',
      passwordResetFail:
        'Det gick tyvärr inte att utföra återställningen. Kontakta administratör för mer hjälp!'
    }
  }
  $scope.form = {
    u: '',
    p: '',
    t: ''
  }
  $scope.useTfa = false
  $scope.inProgress = false
  $scope.loginErrorMessage = ''
  $scope.loginError = false
  $scope.forgotPassword = false
  $scope.forgotPasswordMessage = false
  $scope.resetPassword = function() {
    $scope.inProgress = true
    $scope.service.resetPassword($scope.form.u).then(
      function(status) {
        if (status == 200) {
          $scope.forgotPasswordMessage =
            $scope.i18n[$scope.language].passwordResetSuccess
        }
        $scope.inProgress = false
      },
      function() {
        $scope.forgotPasswordMessage =
          $scope.i18n[$scope.language].passwordResetFail
        $scope.inProgress = false
      }
    )
  }
  $scope.login = function() {
    $scope.inProgress = true
    $scope.loginErrorMessage = ''
    $scope.loginError = false
    $scope.service.login($scope.form.u, $scope.form.p, $scope.form.t).then(
      function(status) {
        $scope.inProgress = false
        if (status == '2fa_required') {
          $scope.useTfa = true
          $scope.tfaMessage = 'Ange kod från authenticator'
          return false
        }
        if (status == 'Ok') {
          $scope.useTfa = false
          $scope.tfaMessage = ''
          $uibModalInstance.close(true)
        }
      },
      function(result) {
        $scope.inProgress = false
        if (result == 'invalid_grant') {
          $scope.loginErrorMessage = 'Inloggningsuppgifterna är ej korrekta!'
          $scope.loginError = true
          return false
        }
        $scope.loginErrorMessage =
          'Ett fel uppstod i kommunikation med servern!'
        $scope.loginError = true

        return false
      }
    )
  }
  $scope.keyup = function(evt) {
    if (evt.keyCode == 13) {
      if (evt.target.id == 'loginViewFormInputPassword' && !$scope.useTfa) {
        $scope.login()
      }
      if (evt.target.id == 'loginViewFormInputTfa' && $scope.useTfa) {
        $scope.login()
      }
      if (
        evt.target.id == 'loginViewFormInputUsername' &&
        $scope.forgotPassword === true
      ) {
        $scope.login()
      }
    }
  }
  // Delay focus of username input when opening
  $timeout(function() {
    $('#loginViewFormInputUsername').focus()
  }, 300)
}
