angular
  .module('app')
  .factory('settingsService', [
    '$http',
    '$q',
    '$uibModal',
    '$timeout',
    'authService',
    settingsServiceFactory
  ])

function settingsServiceFactory($http, $q, $uibModal, $timeout) {
  var self = {
    open: function() {
      var deferred = $q.defer()
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'settingsView.html',
        keyboard: false,
        backdrop: 'static',
        controller: 'settingsCtrl',
        size: 'md',
        resolve: {
          service: function() {
            return {
              getSettings: self.getSettings,
              saveSettings: self.saveSettings,
              sendTestMail: self.sendTestMail
            }
          }
        }
      })
      modalInstance.result.then(
        function() {
          return deferred.resolve()
        },
        function() {}
      )
      return deferred.promise
    },
    getSettings: function() {
      var deferred = $q.defer()
      deferred.resolve({
        alertEmail: ''
      })
      return deferred.promise
    },
    saveSettings: function() {
      var deferred = $q.defer()
      $timeout(function() {
        return deferred.reject(true)
      }, 2000)

      //deferred.resolve(true);
      return deferred.promise
    },
    sendTestMail: function() {
      var deferred = $q.defer()
      $timeout(function() {
        return deferred.reject(true)
      }, 2000)

      //deferred.resolve(true);
      return deferred.promise
    }
  }
  return self
}
