angular.module('app').controller('navBarCtrl', [
  '$scope',
  'changelogService',
  function($scope, changelogService) {
    $scope.language = 'se'
    $scope.i18n = {
      se: {
        lblTools: 'Verktyg',
        lblToolsChangelog: 'Changelog'
      }
    }
    
    $scope.version = ''

    $scope.tools = [
      {
        label: 'lblToolsChangelog',
        iconClass: 'fa-magnet',
        click: function() {
          changelogService.open()
        }
      }
    ]
  }
])
