angular
  .module('app')
  .controller('changelogCtrl', [
    '$scope',
    '$q',
    '$uibModalInstance',
    'service',
    changelogController
  ])

function changelogController($scope, $q, $uibModalInstance, service) {
  $scope.data = {}

  $scope.language = 'se'
  $scope.i18n = {
    se: {
      modalTitle: 'Ändringslogg',
      buttonClose: 'Stäng'
    },
    en: {
      modalTitle: 'Changelog',
      buttonClose: 'Close'
    }
  }
  $scope.update = function() {
    var deferred = $q.defer()
    service.get().then(function(data) {
      var changelogData = {}
      for (var i = 0; i < data.length; i++) {
        var item = data[i]
        if (!changelogData[item.version]) {
          changelogData[item.version] = {
            version: item.version,
            list: []
          }
        }
        changelogData[item.version].list.push(item)
      }
      var list = []
      for (var x in changelogData) {
        list.push(changelogData[x])
      }
      $scope.data = list
    })
    return deferred.promise
  }
  $scope.update()
  $scope.close = function() {
    $uibModalInstance.close(false)
  }
}
