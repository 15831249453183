angular.module('app').factory('mainService', [
  '$http',
  '$q',
  'apiFactory',
  'authService',
  function mainService($http, $q, apiFactory, authService) {
    var self = {
      _lastGetListRequestId: 0,
      getList: function(options) {
        var deferred = $q.defer()
        // Create request id
        requestId = Math.floor((1 + Math.random()) * 1000000000) + 1000000000
        self.getListExecute(options, requestId).then(
          function(data) {
            if (data.requestId == _lastGetListRequestId) {
              return deferred.resolve(data)
            } else {
              return deferred.reject(true)
            }
          },
          function() {
            return deferred.reject(false)
          }
        )
        _lastGetListRequestId = requestId
        return deferred.promise
      },
      getListExecute: function(options, requestId) {
        // HTTP service
        var deferred = $q.defer()
        var url = globalConfig.api.staff.path
        var config = apiFactory.getDefaultConfigWithAuthHeader()
        config.params = {
          filter: {}
        }
        if (options) {
          if (options.skip >= 0) {
            config.params.skip = parseInt(options.skip, 10)
          }
          if (options.take >= 0) {
            config.params.take = parseInt(options.take, 10)
          }
          if (options.competenceId > 0) {
            config.params.filter.competenceId = parseInt(
              options.competenceId,
              10
            )
          }
          if (typeof options.name == 'string') {
            config.params.filter.name = options.name
          }
          if (typeof options.employeeNumber == 'string') {
            config.params.filter.employeeNumber = options.employeeNumber
          }
          if (typeof options.socialSecurityNumber == 'string') {
            config.params.filter.socialSecurityNumber =
              options.socialSecurityNumber
          }
          if (options.districtId > 0) {
            config.params.filter.districtId = options.districtId
          }
          if (options.status > 0) {
            config.params.filter.status = options.status
          }
        }
        apiFactory.get(url, config).then(
          function(response) {
            var list = []
            for (var i = 0; i < response.data.list.length; i++) {
              var item = response.data.list[i]
              item.name = item.firstName + ' ' + item.lastName
              list.push(item)
            }
            return deferred.resolve({
              list: list,
              requestId: requestId,
              count: response.data.count,
              skip: response.data.skip,
              take: response.data.take
            })
          },
          function() {
            return deferred.reject()
          }
        )
        return deferred.promise
      },
      getCompetences: function() {
        // HTTP service
        var deferred = $q.defer()
        var url = globalConfig.api.competence.path
        var config = apiFactory.getDefaultConfigWithAuthHeader()

        //order: ['B', 'B96', 'BE', 'C1', 'C1E', 'C', 'CE', 'D1', 'D1E', 'D', 'DE', 'Förarkort']
        //         0    1      2     3     4      5    6     7     8      9    10    11

        apiFactory.get(url, config).then(
          function(response) {
            response.data.list.sort(self._fnCompetenceSort)
            return deferred.resolve(response.data.list)
          },
          function() {
            return deferred.reject()
          }
        )
        return deferred.promise
      },
      getCompetenceGroups: function() {
        // HTTP service
        var deferred = $q.defer()
        var url = globalConfig.api.competenceGroup.path
        var config = apiFactory.getDefaultConfigWithAuthHeader()
        apiFactory.get(url, config).then(
          function(response) {
            for (var i = 0; i < response.data.list.length; i++) {
              response.data.list[i].competences.sort(self._fnCompetenceSort)
            }
            return deferred.resolve(response.data.list)
          },
          function() {
            return deferred.reject()
          }
        )
        return deferred.promise
      },
      get: function(id) {
        // HTTP service
        var deferred = $q.defer()
        var url = globalConfig.api.staff.path + '/' + id
        var config = apiFactory.getDefaultConfigWithAuthHeader()

        apiFactory.get(url, config).then(
          function(response) {
            var data = response.data
            var item = {
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              name: data.forstName + ' ' + data.lastName,
              employeeNumber: data.employeeNumber,
              districtId: data.districtId ? data.districtId : 6, // <-- TODO Temporary
              socialSecurityNumber: data.socialSecurityNumber,
              note: data.note,
              expectedCompetences: []
            }

            for (var i = 0; i < data.expectedCompetences.length; i++) {
              var comp = data.expectedCompetences[i]
              item.expectedCompetences.push({
                id: comp.id,
                competenceId: comp.competenceID,
                status: comp.status,
                latestCheck: comp.latestCheck
              })
            }
            return deferred.resolve(item)
          },
          function() {
            return deferred.reject()
          }
        )
        return deferred.promise
      },
      getDistricts: function() {
        var deferred = $q.defer()
        var url = globalConfig.api.districts.path
        var config = apiFactory.getDefaultConfigWithAuthHeader()
        if (self.districts.length == 0) {
          apiFactory.get(url, config).then(
            function(response) {
              self.districts = response.data.list
              return deferred.resolve(self.districts)
            },
            function() {
              return deferred.reject()
            }
          )
        } else {
          deferred.resolve(self.districts)
        }
        return deferred.promise
      },
      userDistricts: function() {
        var deferred = $q.defer()
        authService.userDistricts().then(
          function(data) {
            return deferred.resolve(data)
          },
          function(data) {
            return deferred.reject(data)
          }
        )
        return deferred.promise
      },
      saveStaff: function(staffItem) {
        var deferred = $q.defer()
        var url = globalConfig.api.staff.path
        var config = apiFactory.getDefaultConfigWithAuthHeader()
        var data = {
          firstName: staffItem.firstName,
          lastName: staffItem.lastName,
          employeeNumber: staffItem.employeeNumber,
          socialSecurityNumber: staffItem.socialSecurityNumber,
          districtId: staffItem.districtId,
          note: staffItem.note
        }
        if (staffItem.id > 0) {
          data.id = staffItem.id
          apiFactory.put(url, data, config).then(
            function() {
              self
                .saveCompetences(staffItem.id, staffItem.expectedCompetences)
                .then(
                  function() {
                    return deferred.resolve()
                  },
                  function() {
                    return deferred.reject()
                  }
                )
            },
            function() {
              return deferred.reject()
            }
          )
        } else {
          apiFactory.post(url, data, config).then(
            function(response) {
              var id = response.data.id
              return self
                .saveCompetences(id, staffItem.expectedCompetences)
                .then(
                  function() {
                    return deferred.resolve()
                  },
                  function() {
                    return deferred.reject()
                  }
                )
            },
            function() {
              self
                .saveCompetences(staffItem.id, staffItem.expectedCompetences)
                .then(
                  function() {
                    return deferred.resolve()
                  },
                  function() {
                    return deferred.reject()
                  }
                )
            }
          )
        }
        return deferred.promise
      },
      deleteStaff: function(id) {
        var deferred = $q.defer()
        var url = globalConfig.api.staff.path + '/' + id
        var config = apiFactory.getDefaultConfigWithAuthHeader()
        if (id > 0) {
          apiFactory.del(url, config).then(
            function() {
              return deferred.resolve()
            },
            function() {
              return deferred.reject({ reason: 'COULD_NOT_DELETE_STAFF' })
            }
          )
        } else {
          return deferred.reject({ reason: 'NO_ID_PROVIDED' })
        }
        return deferred.promise
      },
      saveCompetences: function(staffId, competences) {
        var deferred = $q.defer()
        var iNext = 0
        var url = globalConfig.api.ExpectedCompetence.path
        var config = apiFactory.getDefaultConfigWithAuthHeader()

        var next = function() {
          var item = competences[iNext]
          if (iNext++ < competences.length) {
            var data = {
              staffID: staffId,
              competenceId: item.id,
              status: item.status
            }

            if (item.status >= 0) {
              if (item.sourceId == 0) {
                // POST - New
                apiFactory.post(url, data, config).then(
                  function() {
                    next()
                  },
                  function() {
                    next()
                  }
                )
              } else {
                // PUT - Update
                data.id = item.sourceId
                apiFactory.put(url, data, config).then(
                  function() {
                    next()
                  },
                  function() {
                    next()
                  }
                )
              }
            } else {
              // DELETE
              apiFactory.del(url + '/' + item.sourceId, config).then(
                function() {
                  next()
                },
                function() {
                  next()
                }
              )
            }
            next()
          } else {
            deferred.resolve()
          }
        }
        next()
        return deferred.promise
      },
      districts: [],
      _fnCompetenceSort: function(a, b) {
        var ao = globalConfig.bky.order.indexOf(a.technicalName)
        var bo = globalConfig.bky.order.indexOf(b.technicalName)
        ao = ao < 0 ? globalConfig.bky.order.length : ao
        bo = bo < 0 ? globalConfig.bky.order.length : bo
        return ao - bo
      }
    }
    return self
  }
])
