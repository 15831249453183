angular
  .module('app')
  .factory('authService', ['$http', '$q', authServiceService])

function authServiceService($http, $q) {
  var self = {
    tokenInfo: '',
    _userInfo: null,
    _districts: [],
    _userDistricts: [],
    _state: '',
    _idToken: '',
    _accessToken: '',
    _tokenType: '',
    _expiresIn: '',
    _scope: '',
    // login: function(u, p, t) {
    //   debugger;
    //   var deferred = $q.defer()
    //   var data = {
    //     Grant_type: 'password',
    //     Username: u,
    //     password: p,
    //     tfa: t
    //   }
    //   var config = {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    //     },
    //     transformRequest: function(obj) {
    //       var str = []
    //       for (var p in obj)
    //         str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    //       return str.join('&')
    //     }
    //   }
    //   debugger;
    //   $http.post(globalConfig.api.token.path, data, config).then(
    //     function(result) {
    //       self.authenticate(result.data).then(
    //         function() {
    //           return deferred.resolve('Ok')
    //         },
    //         function() {
    //           return deferred.reject('Client error')
    //         }
    //       )
    //     },
    //     function(result) {
    //       if (result.data.error === '2fa_required') {
    //         return deferred.resolve(result.data.error)
    //       }
    //       return deferred.reject(result.data.error)
    //     }
    //   )
    //   return deferred.promise
    // },
    // authenticate: function() {
    //   debugger;
    //   var deferred = $q.defer()
    //   if (arguments.length > 0) {
    //     var data = arguments[0]
    //     localStorage._token = data.access_token
    //     localStorage._token_type = data.token_type
    //     localStorage._token_issued = data['.issued']
    //     localStorage._token_expires = data['.expires']
    //     localStorage._userName = data.userName
    //   }

    //   self.userInfo().then(
    //     function() {
    //       return deferred.resolve(true)
    //     },
    //     function() {
    //       return deferred.reject(false)
    //     }
    //   )

    //   deferred.resolve(true)
    //   return deferred.promise
    // },
    // userInfo: function() {
    //   debugger;
    //   var deferred = $q.defer()
    //   if (self._userInfo === null) {
    //     if (localStorage.authSessionData_accessToken) {
    //       var url = globalConfig.api.userInfo.path
    //       var config = {
    //         headers: {
    //           Authorization: 'Bearer ' + localStorage.authSessionData_accessToken,
    //           'Content-Type': 'application/json; charset=utf-8'
    //         }
    //       }
    //       $http.get(url, config).then(
    //         function(result) {
    //           debugger;
    //           self._userInfo = result.data
    //           return deferred.resolve(self._userInfo)
    //         },
    //         function() {
    //           return deferred.reject(false)
    //         }
    //       )
    //     } else {
    //       deferred.reject(false)
    //     }
    //   } else {
    //     deferred.resolve(self._userInfo)
    //   }
    //   return deferred.promise
    // },
    districts: function () {
      // Get districts
      var deferred = $q.defer()
      if (self._districts.length === 0) {
        var url = globalConfig.api.districts.path
        var config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.authSessionData_accessToken,
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
        $http.get(url, config).then(
          function (response) {
            self._districts = response.data.list
            return deferred.resolve(self._districts)
          },
          function () {
            return deferred.reject()
          }
        )
      } else {
        deferred.resolve(self._districts)
      }
      return deferred.promise
    },
    userDistricts: function () {
      var deferred = $q.defer()
      if (self._userDistricts.length === 0) {
        self.districts().then(
          function () {
            var ssoUser = JSON.parse(localStorage.getItem(window.globalConfig.auth.ssoUser));
            if (ssoUser && ssoUser.access) {
              var accessList = []
              ssoUser.access
                .filter(function (element) {
                  return (
                    ['COMPETENCE_EDIT', 'COMPETENCE_LIST'].indexOf(element.a) >=
                    0
                  )
                })
                .map(function (element) {
                  return element.d
                })
                .forEach(function (element) {
                  element.forEach(function (item) {
                    if (accessList.indexOf(item) === -1) {
                      accessList.push(item)
                    }
                  })
                })
              districtsList = accessList
                .map(function (element) {
                  return self._districts.find(function (item) {
                    return element === item.id
                  })
                })
                .filter(function (element) {
                  return !!element
                })
              self._userDistricts = districtsList
              return deferred.resolve(districtsList)
            } else {
              return deferred.reject()
            }
            // self.userInfo().then(
            //   function(data) {
            //     // Complicated. Will be rewritten when start to use jwt tokens
            //     var accessList = []
            //     data.access
            //       .filter(function(element) {
            //         return (
            //           ['COMPETENCE_EDIT', 'COMPETENCE_LIST'].indexOf(
            //             element.accessName
            //           ) >= 0
            //         )
            //       })
            //       .map(function(element) {
            //         return element.districts
            //       })
            //       .forEach(function(element) {
            //         element.forEach(function(item) {
            //           if (accessList.indexOf(item) === -1) {
            //             accessList.push(item)
            //           }
            //         })
            //       })
            //     districtsList = accessList
            //       .map(function(element) {
            //         return self._districts.find(function(item) {
            //           return element === item.id
            //         })
            //       })
            //       .filter(function(element) {
            //         return !!element
            //       })
            //     self._userDistricts = districtsList
            //     return deferred.resolve(districtsList)
            //   },
            //   function() {
            //     deferred.reject()
            //   }
            // )
          },
          function () {
            return deferred.reject()
          }
        )
      } else {
        deferred.resolve(self._userDistricts)
      }
      return deferred.promise
    },
    logout: function () {
      // var deferred = $q.defer()
      // var data = {}
      // var config = {
      //   headers: {
      //     Authorization: 'Bearer ' + localStorage.authSessionData_accessToken,
      //     'Content-Type': 'application/json; charset=utf-8'
      //   }
      // }
      // $http
      //   .post(globalConfig.api.logout.path, data, config)
      //   .finally(function() {
      //     delete localStorage._token
      //     delete localStorage._token_type
      //     delete localStorage._token_issued
      //     delete localStorage._token_expires
      //     delete localStorage._userName
      //     self._userInfo = null
      //     self._districts = []
      //     self._userDistricts = []

      //     deferred.resolve(true)
      //   })
      // return deferred.promise

      var token = localStorage.getItem(window.globalConfig.auth.id_token)

      localStorage.removeItem(window.globalConfig.auth.accessToken)
      localStorage.removeItem(window.globalConfig.auth.id_token)
      localStorage.removeItem(window.globalConfig.auth.ssoUser)
      localStorage.removeItem(window.globalConfig.auth.tokenExpiresAt)
      localStorage.removeItem(window.globalConfig.auth.accessTokenType)
      localStorage.removeItem(window.globalConfig.auth.ssoState)
      window.location.href =
        window.globalConfig.auth.endSessionEndpoint +
        '?' +
        'id_token_hint' +
        encodeURIComponent('=') +
        encodeURIComponent(token) +
        encodeURIComponent('&') +
        'post_logout_redirect_uri' +
        encodeURIComponent('=') +
        encodeURIComponent(window.location.origin)
    },
    handleSSOCallback: function () {
      var url = new URL(window.location.href.replace('/#/sso_callback#', '?'))
      self._idToken = url.searchParams.get('id_token')
      self._scope = url.searchParams.get('scope')
      self._state = url.searchParams.get('state')
      self._accessToken = url.searchParams.get('access_token')
      self._tokenType = url.searchParams.get('token_type')
      self._expiresIn = url.searchParams.get('expires_in')

      if (self.isValidSsoTokenParameters() && self.isCurrentTokenState()) {
        localStorage.setItem(window.globalConfig.auth.id_token, self._idToken)
      }
    },
    isCurrentTokenState: function () {
      return self._state === localStorage.getItem('ssoState')
    },
    isValidSsoTokenParameters: function () {
      return (
        null != self._state &&
        null != self._accessToken &&
        null != self._tokenType &&
        null != self._expiresIn &&
        null != self._scope
      )
    },
    getExchangeToken: function () {
      var deferred = $q.defer()
      var url = window.globalConfig.auth.exchangeTokenEndpoint
      var config = {
        headers: {
          Authorization: self._tokenType + ' ' + self._accessToken,
          'Cache-Control': 'no-cache',
        },
      }
      $http.get(url, config).then(
        function (response) {
          localStorage.setItem(
            window.globalConfig.auth.accessToken,
            response.data.access_token
          )
          localStorage.setItem(
            window.globalConfig.auth.accessTokenType,
            response.data.token_type
          )
          var expiration = new Date()
          expiration.setSeconds(
            expiration.getSeconds() + Number(response.data.expires_in)
          )
          localStorage.setItem(
            window.globalConfig.auth.tokenExpiresAt,
            expiration.toString()
          )
          localStorage.setItem(
            window.globalConfig.auth.ssoUser,
            JSON.stringify(self.decodeJwtToken(response.data.access_token))
          )
          return deferred.resolve(response)
        },
        function () {
          return deferred.reject()
        }
      )
      return deferred.promise
    },
    decodeJwtToken: function (jwt_token) {
      var result = {}
      if (jwt_token) {
        var base64Url = jwt_token.split('.')[1]
        var base64 = base64Url.replace('-', '+').replace('_', '/')
        var userJson = JSON.parse(self.b64DecodeUnicode(base64))
        result = userJson
      }
      return result
    },
    b64DecodeUnicode: function (jwt_token) {
      return decodeURIComponent(
        Array.prototype.map
          .call(atob(jwt_token), function (char) {
            return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
    },
    validateAccessByToken: function () {
      var result = false
      var accessToken = localStorage.getItem(
        window.globalConfig.auth.accessToken
      )
      var tokenExpiresAt = localStorage.getItem(
        window.globalConfig.auth.tokenExpiresAt
      )
      if (
        accessToken &&
        tokenExpiresAt &&
        new Date(tokenExpiresAt) > new Date()
      ) {
        result = true
      }
      return result
    },
  }
  return self
}
