angular
  .module('app')
  .controller('mainListCtrl', [
    '$scope',
    'mainListService',
    'bkyEditPersonService',
    mainListController
  ])

function mainListController($scope, mainListService, bkyEditPersonService) {
  'use strict'
  $scope.language = 'se'
  $scope.i18n = {
    se: {
      labelTitle: 'Filter',
      labelFilterType: 'Behörighet',
      labelFilterName: 'Namn',
      labelEmployeeNumber: 'Anställningsnr',
      labelSocialSecurityNumber: 'Personnummer',
      labelDistrict: 'Driftområde',
      labelStatus: 'Status',
      labelStatusText: 'Visa endast varningar',
      labelThings: 'Åtgärder'
    },
    en: {
      labelTitle: 'Filter',
      labelFilterType: 'Competence',
      labelFilterName: 'Name',
      labelEmployeeNumber: 'Employee number',
      labelSocialSecurityNumber: 'Social Security Number',
      labelDistrict: 'District',
      labelStatus: 'Status',
      labelStatusText: 'Show warnings only',
      labelThings: 'Åtgärder'
    }
  }
  // Init parameters
  $scope.initDone = false

  // Debouncetime in search
  $scope.debounceTime = 500
  $scope.list = []
  $scope.competences = []

  // Filter
  $scope.filter = {
    competenceId: 0,
    name: '',
    employeeNumber: '',
    socialSecurityNumber: '',
    districtId: 0,
    status: 0
  }
  // Filter - CompetenceId
  $scope.selectFilterCompetence = function(id) {
    $scope.filter.competenceId = id
  }

  $scope.$watch('filter.competenceId', function(newVal, oldVal) {
    $scope.updateListWatch(newVal, oldVal)
  })

  // Filter - Name
  $scope.$watch('filter.name', function(newVal, oldVal) {
    $scope.updateListWatch(newVal, oldVal)
  })

  // Filter - EmployeeNumber
  $scope.$watch('filter.employeeNumber', function(newVal, oldVal) {
    $scope.updateListWatch(newVal, oldVal)
  })

  // Filter - SocialSecurityNumber
  $scope.$watch('filter.socialSecurityNumber', function(newVal, oldVal) {
    $scope.updateListWatch(newVal, oldVal)
  })

  // Filter - District
  $scope.$watch('filter.districtId', function(newVal, oldVal) {
    if (newVal > 0) {
      localStorage._lastSelectedDistrictId = newVal
      $scope.pagination.page = 1
    }
    $scope.updateListWatch(newVal, oldVal)
  })

  // Filter - Status
  $scope.$watch('filter.status', function(newVal, oldVal) {
    $scope.updateListWatch(newVal, oldVal)
  })

  // Get districts
  $scope.districts = []
  $scope.globalDistricts = []
  mainListService.getDistricts().then(
    function(data) {
      $scope.districts = data
      if (localStorage._lastSelectedDistrictId) {
        $scope.filter.districtId = parseInt(
          localStorage._lastSelectedDistrictId,
          10
        )
      } else {
        $scope.filter.districtId = $scope.districts[0].id
      }
      $scope.initDone = true
    },
    function() {}
  )
  mainListService.getGlobalDistricts().then(
    function(data) {
      var list = {}
      for (var i = 0; i < data.length; i++) {
        list[data[i].id] = data[i]
      }
      $scope.globalDistricts = list
    },
    function() {}
  )
  mainListService.getCompetences().then(
    function(data) {
      var list = [{ id: 0, name: 'Ingen filtrering', status: -1, show: true }]
      for (var i = 0; i < data.length; i++) {
        var item = data[i]
        item.status = -1
        item.show =
          globalConfig.bky.columns.defaultColumns.indexOf(item.technicalName) >=
          0
            ? true
            : false
        list.push(item)
      }
      $scope.competences = list
    },
    function(response) {}
  )
  // Column view
  $scope.columnFilterList = mainListService.listColumns()
  $scope.edit = function(id) {
    mainListService
      .get(id)
      .then(
        function(data) {
          return bkyEditPersonService.editStaff(data)
        },
        function() {}
      )
      .finally(function() {
        $scope.updateList()
      })
  }
  $scope.newStaff = function() {
    bkyEditPersonService
      .createStaff($scope.filter.districtId)
      .finally(function() {
        $scope.updateList()
      })
  }
  $scope.pagination = {
    skip: 0,
    take: 15,
    page: 1,
    total: 50,
    pages: 5
  }
  $scope.$watch('pagination.page', function(page) {
    $scope.pagination.skip = $scope.pagination.take * (page - 1)
    $scope.updateList()
  })

  // Update list
  $scope.updateList = function() {
    if (!$scope.initDone) {
      return false
    }
    var options = { skip: $scope.pagination.skip, take: $scope.pagination.take }
    // CompetenceId if set
    if ($scope.filter.competenceId > 0) {
      options.competenceId = $scope.filter.competenceId
    }
    // Name
    if ($scope.filter.name.length > 0) {
      options.name = $scope.filter.name
    }
    // Employee Number
    if ($scope.filter.employeeNumber.length > 0) {
      options.employeeNumber = $scope.filter.employeeNumber
    }
    // Social Security Number
    if ($scope.filter.socialSecurityNumber.length > 0) {
      options.socialSecurityNumber = $scope.filter.socialSecurityNumber
    }
    // District
    if ($scope.filter.districtId > 0) {
      options.districtId = $scope.filter.districtId
    }
    // District
    if ($scope.filter.status > 0) {
      options.status = $scope.filter.status
    }
    mainListService.getList(options).then(
      function(data) {
        $scope.pagination.total = data.count
        var list = []
        for (var i = 0; i < data.list.length; i++) {
          var item = data.list[i]
          item.competences = []
          // Check if competence is ok
          for (var j = 1; j < $scope.competences.length; j++) {
            var cItem = {
              id: $scope.competences[j].id,
              name: $scope.competences[j].name,
              status: -1
            }
            for (var k = 0; k < item.expectedCompetences.length; k++) {
              if (item.expectedCompetences[k].competenceID == cItem.id) {
                cItem.status = item.expectedCompetences[k].status
              }
            }
            item.competences.push(cItem)
          }
          list.push(item)
        }
        $scope.list = list
      },
      function(error) {}
    )
  }
  $scope.updateListWatch = function(newVal, oldVal) {
    if (newVal !== oldVal) {
      $scope.updateList()
    }
  }
  $scope.updateList()
}
