angular
  .module('app')
  .controller('mainCtrl', [
    '$scope',
    'authService',
    'loginService',
    'settingsService',
    mainController,
  ])

function mainController($scope, authService, loginService, settingsService) {
  $scope.isAuth = false

  $scope.logout = function () {
    authService.logout()  
  }
  $scope.settings = function () {
    settingsService.open()
  }
  $scope.checkAuth = function () {
    // return authService.userInfo()
    //   .then(
    //     function () {
    //       $scope.isAuth = true
    //     },
    //     function () {
    //        debugger;
    //       // loginService.open()
    //       //   .then(
    //       //     function () {
    //       //       $scope.isAuth = true
    //       //     },
    //       //     function () {
    //       //     }
    //       //   )
    //
    //
    //     }
    //   )
    if (!authService.validateAccessByToken()) {
      $scope.goToSSOPage()
    } else {
      $scope.isAuth = true
    }
  }

  $scope.goToSSOPage = function () {
    var ssoState = $scope.createGuid()
    localStorage.setItem('ssoState', ssoState)
    var loginUrl =
      globalConfig.auth.login +
      '?' +
      'ReturnUrl=' +
      encodeURIComponent('/connect/authorize/callback?') +
      $scope.transformRequest({
        client_id: globalConfig.auth.clientId,
        response_type: encodeURIComponent('id_token token'),
        redirect_uri: encodeURIComponent(globalConfig.auth.redirectUri),
        scope: encodeURIComponent('openid user'),
        state: ssoState,
        nonce: $scope.createGuid(),
        require_consent: false,
      })
    window.location.href = loginUrl
  }

  $scope.checkIfSSOCallback = function () {
    var currentURLHash = window.location.hash
    if (currentURLHash.indexOf('sso_callback') > -1) {
      authService.handleSSOCallback()

      authService.getExchangeToken().then(function () {
        window.location.href = '/'
        $scope.isAuth = true
      })
    } else {
      $scope.checkAuth()
    }
  }

  $scope.createGuid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0
      var v = c === 'x' ? r : (r && 0x3) | 0x8
      return v.toString(16)
    })
  }

  $scope.transformRequest = function (obj) {
    var str = []
    for (var p in obj)
      str.push(
        encodeURIComponent(p) +
          encodeURIComponent('=') +
          encodeURIComponent(obj[p])
      )
    return str.join(encodeURIComponent('&'))
  }
  $scope.checkIfSSOCallback()

  $scope.version = ''
}
