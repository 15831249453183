angular
  .module('app')
  .factory('bkyEditPersonService', [
    '$q',
    '$uibModal',
    'mainService',
    bkyEditPersonService
  ])

function bkyEditPersonService($q, $uibModal, mainService) {
  var self = {
    editStaff: function(data) {
      var deferred = $q.defer()
      var modalInstance = $uibModal.open({
        templateUrl: 'bkyEditPersonView.html',
        controller: 'bkyEditPersonCtrl',
        keyboard: false,
        backdrop: 'static',
        size: 'lg',
        resolve: {
          service: function() {
            return {
              getCompetences: self.getCompetences,
              getDistricts: self.getDistricts,
              userDistricts: self.userDistricts,
              getCompetenceGroups: self.getCompetenceGroups,
              save: self.save,
              delete: self.delete,
              data: data
            }
          }
        }
      })
      modalInstance.result.then(function() {
        return deferred.resolve()
      })

      return deferred.promise
    },
    createStaff: function(districtId) {
      if (!districtId) {
        districtId = 0
      }
      return self.editStaff({
        districtId: districtId,
        employeeNumber: '',
        firstName: '',
        lastName: '',
        note: '',
        id: 0,
        socialSecurityNumber: '',
        expectedCompetences: []
      })
    },
    delete: function(id) {
      return mainService.deleteStaff(id)
    },
    getCompetences: function(group) {
      return mainService.getCompetences(group)
    },
    getCompetenceGroups: function(group) {
      return mainService.getCompetenceGroups(group)
    },
    getDistricts: function() {
      return mainService.getDistricts()
    },
    userDistricts: function() {
      return mainService.userDistricts()
    },

    save: function(data) {
      var deferred = $q.defer()
      mainService.saveStaff(data).then(
        function() {
          return deferred.resolve()
        },
        function() {
          return deferred.reject()
        }
      )
      return deferred.promise
    }
  }
  return self
}
