angular.module('app').directive('onenterfocusnext', function() {
  return {
    restrict: 'A',
    link: function($scope, elem, attrs) {
      elem.bind('keydown', function(e) {
        var code = e.keyCode || e.which
        if (code === 13 && !e.shiftKey) {
          e.preventDefault()
          var next = window.document.getElementById(attrs.onenterfocusnext)
          if (next) {
            next.focus()
          }
        }
      })
    }
  }
})
