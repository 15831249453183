angular
  .module('app')
  .controller('settingsCtrl', [
    '$scope',
    '$q',
    '$uibModalInstance',
    'service',
    settingsController
  ])

function settingsController($scope, $q, $uibModalInstance, service) {
  $scope.language = 'se'
  $scope.i18n = {
    se: {
      modalTitle: 'Inställningar',
      buttonClose: 'Stäng',
      buttonSave: 'Spara',
      labelSendAlertMail: 'Skicka uppdateringsmail till',
      labelTryAlert: 'Skicka testmail',
      buttonTryAlert: 'Skicka',
      textErrorGetSettings: 'Dina inställningar gick inte att ladda!',
      saveStatus: {
        1: 'Inställningarna sparade!',
        2: 'Det gick inte att spara inställningarna!'
      },
      sendTestStatus: {
        1: 'Testmeddelandet skickat!',
        2: 'Det gick inte att skicka testmeddelande!'
      }
    },
    en: {
      modalTitle: 'Settings',
      buttonClose: 'Close',
      buttonSave: 'Save',
      labelSendAlertMail: 'Send alert mail to',
      labelTryAlert: 'Send alert mail test',
      buttonTryAlert: 'Send',
      textErrorGetSettings: 'Your settings could not be loaded.',
      saveStatus: {
        1: 'Settings saved!',
        2: 'Settings couldn´t be saved!'
      },
      sendTestStatus: {
        1: 'Testmessage sent!',
        2: 'Textmessage send failed!'
      }
    }
  }
  $scope.alertEmail = ''
  $scope.loading = true
  $scope.pendingSave = false
  $scope.pendingSend = false
  service.getSettings().then(
    function(data) {
      $scope.alertEmail = data.alertEmail
      $scope.loading = false
    },
    function() {
      alert($scope.i18n[$scope.language].textErrorGetSettings)
      $scope.loading = false
    }
  )
  $scope.close = function() {
    if (!$scope.pendingSave && !$scope.pendingSend) {
      $uibModalInstance.close(false)
    }
  }
  $scope.saveStatus = 0
  $scope.save = function() {
    $scope.pendingSave = true
    $scope.saveStatus = 0
    $scope.sendTestStatus = 0
    var settings = {
      alertEmail: $scope.alertEmail
    }
    service.saveSettings(settings).then(
      function() {
        $scope.pendingSave = false
        //$uibModalInstance.close(false);
      },
      function() {
        $scope.pendingSave = false
        $scope.saveStatus = 2
      }
    )
  }
  $scope.sendTestStatus = 0
  $scope.sendTestMail = function() {
    $scope.saveStatus = 0
    $scope.sendTestStatus = 0
    $scope.pendingSend = true
    service
      .sendTestMail()
      .then(
        function() {
          $scope.sendTestStatus = 1
        },
        function() {
          $scope.sendTestStatus = 2
        }
      )
      .finally(function() {
        $scope.pendingSend = false
      })
  }
}
