angular
  .module('app')
  .factory('changelogService', ['$q', '$uibModal', changelogServiceFactory])

function changelogServiceFactory($q, $uibModal) {
  var self = {
    open: function() {
      var deferred = $q.defer()
      var modalInstance = $uibModal.open({
        templateUrl: 'changelogView.html',
        controller: 'changelogCtrl',
        keyboard: false,
        backdrop: 'static',
        size: 'lg',
        resolve: {
          service: function() {
            return {
              get: self.getChangelog
            }
          }
        }
      })
      modalInstance.result.then(function() {
        return deferred.resolve()
      })

      return deferred.promise
    },
    getChangelog: function() {
      console.log('getChangelog')
      var deferred = $q.defer()
      deferred.resolve([
        {
          version: '1.3.2',
          text: 'Första publika release'
        },
        {
          version: '1.3.3',
          text: 'Ändringslogg tillagd'
        },
        {
          version: '1.3.3',
          text:
            'Kompetensen "YKBBuss ej TS" tillagd. Används för yrkeskompetensbevis som inte handhas av transportstyrelsen, till exempel utländska YKB-bevis.'
        },
        {
          version: '1.3.4',
          text: 'Zendesk supportmodul tillagd.'
        }
      ])
      return deferred.promise
    }
  }
  return self
}
