angular
  .module('app')
  .factory('loginService', [
    '$http',
    '$q',
    '$uibModal',
    'authService',
    loginServiceFactory
  ])
  
function loginServiceFactory($http, $q, $uibModal, authService) {
  var self = {
    // open: function() {
    //   var deferred = $q.defer()
    //   var modalInstance = $uibModal.open({
    //     animation: true,
    //     ariaLabelledBy: 'modal-title',
    //     ariaDescribedBy: 'modal-body', 
    //     templateUrl: 'loginView.html',
    //     keyboard: false,
    //     backdrop: 'static',
    //     controller: 'loginCtrl', 
    //     size: 'md',
    //     resolve: {
    //       data: function() {
    //         return {
    //           service: self
    //         }
    //       }
    //     }
    //   })
    //   modalInstance.result.then(
    //     function(data) {
    //       return deferred.resolve(data)
    //     },
    //     function() {}
    //   )

    //   return deferred.promise
    // },
    // login: function(u, p, t) {
    //   debugger;
    //   var deferred = $q.defer()
    //   authService.login(u, p, t).then(
    //     function(status) {
    //       return deferred.resolve(status)
    //     },
    //     function(status) {
    //       return deferred.reject(status)
    //     }
    //   )
    //   return deferred.promise
    // },
    // resetPassword: function(e) {
    //   debugger;
    //   var deferred = $q.defer()
    //   debugger;
    //   var url = globalConfig.api.resetPassword.path
    //   var config = {
    //     params: {
    //       email: e
    //     }
    //   }
    //   $http.get(url, config).then(
    //     function(result) {
    //       return deferred.resolve(result.status)
    //     },
    //     function(result) {
    //       return deferred.reject(result.status)
    //     }
    //   )
    //   return deferred.promise  
    // }
  }
  return self
}
