angular
  .module('app')
  .controller('bkyEditPersonCtrl', [
    '$scope',
    '$q',
    '$uibModalInstance',
    'service',
    bkyEditPersonController
  ])

function bkyEditPersonController($scope, $q, $uibModalInstance, service) {
  $scope.formatSSN = function(ssn) {
    ssn.toString()
    if (ssn.length == 10) {
      return (text = ssn.substr(0, 6) + '-' + ssn.substr(6, 4))
    } else if (ssn.length == 12) {
      return (text = ssn.substr(0, 8) + '-' + ssn.substr(8, 4))
    }
    return ssn
  }

  $scope.language = 'se'
  $scope.i18n = {
    se: {
      modalTitle: 'BKY behörighet',
      buttonClose: 'Stäng',
      buttonSave: 'Spara',
      buttonReset: 'Reset',
      buttonDelete: 'Ta bort',
      labelFirstName: 'Förnamn',
      labelLastName: 'Efternamn',
      labelSocialSecurityNumber: 'Personnummer',
      labelEmployeeNumber: 'Anställningsnummer',
      labelDistrict: 'Driftområde',
      labelCompetences: 'Behörigheter',
      labelNote: 'Notering',
      confirmDeleteMessage: 'Är du säker på att du vill ta bort posten?'
    },
    en: {
      modalTitle: 'BKY competence',
      buttonClose: 'Close',
      buttonSave: 'Save',
      buttonReset: 'Reset',
      buttonDelete: 'Delete',
      labelFirstName: 'First name',
      labelLastName: 'Lasts name',
      labelSocialSecurityNumber: 'Social Security Number',
      labelEmployeeNumber: 'Employee number',
      labelDistrict: 'District',
      labelCompetences: 'Competences',
      labelNote: 'Note',
      confirmDeleteMessage: 'Are you shure you want to delete the post?'
    }
  }
  $scope.saveError = ''
  $scope.classes = {
    ignore: -1,
    ask: 0,
    yes: 1,
    reject: 2
  }
  $scope.patterns = {
    socialSecurityNumber: '[0-9]{6,8}(-)?[0-9]{4}'
  }
  $scope.data = {
    // expectedCompetences
    master: {
      firstName: service.data.firstName,
      lastName: service.data.lastName,
      employeeNumber: service.data.employeeNumber,
      socialSecurityNumber: service.data.socialSecurityNumber,
      id: service.data.id,
      districtId: service.data.districtId,
      note: service.data.note
    },
    expectedCompetences: service.data.expectedCompetences
  }
  $scope.$watch('data.master.socialSecurityNumber', function(value) {
    $scope.data.master.socialSecurityNumber = $scope.formatSSN(value)
  })

  $scope.districts = []
  //service.getDistricts()
  service.userDistricts().then(
    function(data) {
      $scope.districts = data
    },
    function() {}
  )

  // Competence groups
  $scope.competenceGroups = []
  $scope.competences = []

  $scope.getCompetences = function() {
    var deferred = $q.defer()
    service.getCompetenceGroups().then(
      function(data) {
        $scope.competenceGroups = data
        var list = []
        for (var i = 0; i < data.length; i++) {
          var group = data[i]
          for (var j = 0; j < group.competences.length; j++) {
            var item = group.competences[j]
            list.push(item)
          }
        }
        return deferred.resolve(list)
      },
      function() {
        return deferred.reject()
      }
    )

    return deferred.promise
  }
  $scope.getCompetences().then(function(list) {
    // All competences
    $scope.competences = list

    // Apply for every competence
    var expected = {}
    var item
    var i
    for (i = 0; i < $scope.data.expectedCompetences.length; i++) {
      item = $scope.data.expectedCompetences[i]
      expected[item.competenceId] = item
    }
    var competences = []
    for (i = 0; i < list.length; i++) {
      item = list[i]
      item.status = -1
      item.statusReset = -1
      item.sourceId = 0
      if (expected[item.id]) {
        item.status = expected[item.id].status
        item.sourceId = expected[item.id].id
      }
      item.statusReset = item.status + 0
      competences.push(item)
    }
    $scope.data.competences = competences
  })
  $scope.getCompetenceData = function(id) {
    for (var i = 0; i < $scope.competences.length; i++) {
      if ($scope.competences[i].id == id) {
        return $scope.competences[i]
      }
    }
    return false
  }

  $scope.toggleCompetence = function(competenceId) {
    for (var i = 0; i < $scope.data.competences.length; i++) {
      if ($scope.data.competences[i].id == competenceId) {
        if ($scope.data.competences[i].source == 0) {
          $scope.data.competences[i].status = { '-1': 1, 0: 1, 1: -1, 2: 1 }[
            $scope.data.competences[i].status
          ]
        } else {
          $scope.data.competences[i].status = { '-1': 0, 0: -1, 1: 0, 2: 0 }[
            $scope.data.competences[i].status
          ]
        }
      }
    }
  }
  $scope.setCompetence = function() {}
  $scope.resetCompetence = function() {
    for (var i = 0; i < $scope.data.competences.length; i++) {
      $scope.data.competences[i].status = $scope.data.competences[i].statusReset
    }
  }
  $scope.composeSaveData = function() {
    var deferred = $q.defer()
    var saveData = angular.copy($scope.data.master)
    saveData.socialSecurityNumber = saveData.socialSecurityNumber.replace(
      /[^0-9]/g,
      ''
    )
    saveData.expectedCompetences = []
    for (var i = 0; i < $scope.competenceGroups.length; i++) {
      var group = $scope.competenceGroups[i]
      for (var j = 0; j < group.competences.length; j++) {
        var item = group.competences[j]
        if (
          (item.statusReset >= 0 || item.status >= 0) &&
          item.status != item.statusReset
        ) {
          saveData.expectedCompetences.push({
            id: item.id,
            status: item.status,
            sourceId: item.sourceId
          })
        }
      }
    }
    deferred.resolve(saveData)
    return deferred.promise
  }
  $scope.close = function() {
    $uibModalInstance.close(false)
  }
  $scope.save = function() {
    var deferred = $q.defer()
    $scope.composeSaveData().then(
      function(data) {
        service.save(data).then(
          function() {
            $uibModalInstance.close(data)
            return deferred.resolve()
          },
          function() {
            $scope.saveError = 'Could not save'
            return deferred.reject()
          }
        )
      },
      function() {
        return deferred.reject()
      }
    )
    return deferred.promise
  }
  $scope.delete = function(id) {
    var deferred = $q.defer()
    if (confirm($scope.i18n[$scope.language].confirmDeleteMessage)) {
      if (id > 0) {
        service.delete(id).then(
          function() {
            $uibModalInstance.close()
            return deferred.resolve()
          },
          function() {
            return deferred.reject()
          }
        )
      } else {
        deferred.reject()
      }
    }
    return deferred.promise
  }
}
